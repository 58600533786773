<template>
  <div class="edit-profile-container">
    <error :errors="errors" />
    <b-overlay :show="busy" rounded="sm">
    <portlet title=" پارول ئۆزگەرتىش ">
      <template slot="body">
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group :state="validateState('old_password')" aria-describedby="oldPassword-live-feedback" id="fieldset-1" description="" label="ھازىرقى پارولىڭىز" label-for="oldPassword">
            <b-form-input type="password" :state="validateState('old_password')" placeholder="نۆۋەتتە ئىشلىتىۋاتقان پارولنى كىرگۈزۈڭ" v-model.trim="old_password" id="oldPassword" trim></b-form-input>
            <b-form-invalid-feedback id="oldPassword-live-feedback">
              نۆۋەتتە ئىشلىتىۋاتقان پارولىڭىزنى كىرگۈزۈڭ
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="row">
            <div class="col-md-6">
              <b-form-group id="fieldset-2" :state="validateState('password')" aria-describedby="password-live-feedback" description="يېڭى پارولىڭىزنى كىرگۈزۈڭ" label="يېڭى پارولىڭىز" label-for="password">
                <b-form-input type="password" :state="validateState('password')" placeholder="ئالماشتۇرماقچى بولغان يېڭى پارولىڭىزنى كىرگۈزۈڭ" v-model="password" id="password" trim></b-form-input>
                <b-form-invalid-feedback id="password-live-feedback">
                  يېڭى پارولىڭىزنىڭ ئۇزۇنلىقى 6 ھەرىپتىن كەم بولمىسۇن
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group :state="validateState('password_confirmation')" aria-describedby="oldPassword-live-feedback" id="fieldset-3" description="" label="يېڭى پارولىڭىز" label-for="confirmPassword">
                <b-form-input type="password" :state="validateState('password_confirmation')" placeholder="ئالماشتۇرماقچى بولغان يېڭى پارولىڭىزنى قايتا كىرگۈزۈڭ" v-model="password_confirmation" id="confirmPassword" trim></b-form-input>
                <b-form-invalid-feedback id="confirmPassword-live-feedback">
                  ئىككى قېتىم كىرگۈزگەن يېڭى پارولىڭىز ئوخشاش بولسۇن
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
          <b-button id="submit" type="submit" variant="primary">
            ساقلاش
          </b-button>
          <b-button class="ml-2" type="reset" variant="danger">
            بىكار قىلىش
          </b-button>
        </b-form>
      </template>
    </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "../../../partials/content/Portlet";
  import AvatarUploader from "../../../partials/layout/AvatarUploader";
  import Error from "../../../partials/widgets/Error";
  import { mapState } from "vuex";
  import { validationMixin } from "vuelidate";
  import { minLength, required, sameAs } from "vuelidate/lib/validators";
  import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs.module";
  import { CHANGE_PASSWORD } from "@/store/modules/auth.module";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "index",
    mixins: [validationMixin, sweetAlertMixin],
    validations: {
      password: { required, minLength: minLength(6) },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
      old_password: { required }
    },
    components: { Portlet, AvatarUploader, Error },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئارخىب باشقۇرۇش", route: "/profile" },
        { title: "پارول ئۆزگەرتىش" }
      ]);
    },
    /*computed: {
      ...mapState({
        errors: state => state.auth.errors
      })
    },*/
    data(){
      return {
        errors: null,
        old_password: '',
        password: '',
        password_confirmation: '',
        busy: false
      };
    },
    methods: {
      onSubmit(){
        this.$v.$touch();
        if (this.$v.$anyError) {
          this.warning({message: 'تولدۇرۇشقا تېگىشلىك جەدىۋەللەرنى تۇلۇق تولدۇرۇڭ'});
          return;
        }
        this.submitState();
        let password = this.password;
        let old_password = this.old_password;
        let password_confirmation = this.password_confirmation;
        const payload = {password, old_password, password_confirmation};
        this.busy = true;
        this.$store.dispatch(CHANGE_PASSWORD, payload)
          .then(()=> {
            this.busy = false;
            this.submitState(false);
            this.success({message: 'پارولىڭىزنى مۇۋاپىقىيەتلىك ئۆزگەرتتىڭىز!'});
          })
          .catch(()=> {this.busy = false; this.submitState(false)})
      },
      submitState(action = true){
        let submitBtn = document.getElementById("submit");
        if ( action ){
          submitBtn.classList.add(
            "kt-spinner",
            "kt-spinner--light",
            "kt-spinner--right"
          );
        }else {
          submitBtn.classList.remove(
            "kt-spinner",
            "kt-spinner--light",
            "kt-spinner--right"
          );
        }
      },
      validateState(name) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      },
    }
  }
</script>

<style scoped>

</style>
